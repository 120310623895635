.more {
  position: relative;
  width: 32px;
  height: 32px;
  border: none;
  background-color: transparent;
  background-image: url('../../../../asset/img/dot_vertical.svg');
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 100;
  border-radius: 4px;

  &:not(:disabled) {
    &:hover, &.active {
      background-color: #e1e8ee;
    }
  }

  &:disabled {
    cursor: not-allowed;
    background-image: url('../../../../asset/img/dot_vertical_disabled.svg');
  }
}