.sort-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #868E96;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}