.input {
    height: 48px;
    min-width: 100px;
    border: 1px solid #DEE2E6;
    border-radius: 4px;
    outline: none;
    font-weight: 500;
    color: #343A40;
    padding: 14px;
    font-size: 16px;
    line-height: 20px;

    &.block {
        width: 100%;
    }

    &:read-only {
        background-color: #F4F4F5;
        border: #F4F4F5;
        cursor: default;
    }

    &::placeholder {
        color: #868E96;
    }

    &.small {
        padding: 6px;
        font-size: 12px;
        line-height: 17px;
    }

    &.medium {
        padding: 14px;
        font-size: 16px;
        line-height: 20px;
    }
}