.modal-container {
  text-align: center;
  min-width: 400px;

  .title {
    margin: 0;
  }

  > .sub {
    margin-top: 16px;
    white-space: break-spaces;
  }

  > .buttons {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}