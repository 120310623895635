.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #343A40;
    margin: 28px 0 32px;

    &.no-margin {
      margin: 0;
    }
  }

  .desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #343A40;
    margin-bottom: 20px;
  }

  .search-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #F5F6F7;
    border-radius: 8px;
    padding: 16px 0;
    margin-bottom: 32px;

    .select {
      width: 150px;
      margin-right: 8px;
    }

    .search-wrapper {
      position: relative;

      .search {
        width: 432px;
        margin-right: 8px;
      }
    }
  }

  .sub-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
}