.message {
  position: fixed;
  width: max-content;
  left: 50%;
  transform: translateX(-50%);
  z-index: 700;
  display: flex;
  align-items: center;
  height: 62px;
  padding: 0 25px;
  background: white;
  border: 1px solid #e9ecef;
  box-shadow: 0 10px 15px rgb(0 0 0 / 1%);
  border-radius: 8px;
  animation: show 0.4s forwards;
  font-weight: 500;
  font-size: 14px;
  color: #25282b;
}

@keyframes show {
  from {
    opacity: 0;
    bottom: 0;
  }

  to {
    opacity: 1;
    bottom: 40px;
  }
}
