.container {
  flex: 1 1;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .format {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;

    .label {
      font-size: 14px;
      margin-right: 8px;
    }

    .radio-area {
      width: 190px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
}

.input {
  position: relative;
  height: 32px;
  padding: 0 10px;
  transition: all .3s;
  border-radius: 4px;
  outline: none;
  border: 1px solid #d7dbdf;


  &:focus, &:hover {
    border: 1px solid #5eb0ef;
    box-shadow: 0 0 0 1px #5eb0ef;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  border-radius: 20px;
  background: #e6e8eb;
  font-weight: bold;
  padding: 5px 15px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    background: #d7dbdf;

  }
}

.radio {
  position: relative;
  display: block;
  padding-left: 25px;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked + .marker::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      left: 4px;
      top: 4px;
      background: #3e63dd;
      border-radius: 100%;
    }
  }

  .marker {
    position: absolute;
    left:0;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 1px solid #c2c9ce;
  }
}