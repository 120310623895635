.container {
  position: fixed;
  left: 200px;
  top: 0;
  padding: 65px 20px 20px 60px;
  width: calc(100% - 200px);
  height: 100%;
  overflow: auto;

  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #343A40;
    padding-bottom: 49px;
    margin: 0;
  }
}