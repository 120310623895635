.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 660px;
  height: 100%;
  overflow: auto;

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #343A40;
    margin: 28px 0 48px;
  }


  .content-list {
    display: flex;
    list-style: none;
    padding: 0;
    magin: 0;

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 15px;
      line-height: 22px;
      margin-right: 8px;
      padding: 13px 18px;
      border: 2px solid #343A40;
      border-radius: 4px;
      cursor: pointer;

      &:hover, &.active {
        .remove svg, .modify svg  {
        fill: white;
        }
      }

      .modify-input {
        outline: none;
        border: none;
        font-weight: 700;
        font-size: 15px;
        line-height: 22px;
        max-width: 150px;
      }

      .modify {
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        border: none;
        outline: none;
        padding: 5px;
        cursor: pointer;
        margin-left: 5px;

        &:hover {
          opacity: .8;
        }
      }

      .remove {
        display: flex;
        align-items: center;
        border:none;
        outline: none;
        background: transparent;
        padding: 5px;;
        cursor: pointer;
        
        &:hover {
          opacity: .8;
        }

      }

      &.active, &:hover {
        color: #FFF;
        background: #121619;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .add-content {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFF;
      border: 1px dashed #ADB5BD;
      border-radius: 4px;
      padding: 10px;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: #ADB5BD;
      cursor: pointer;
      transition: .4s all;

      &:hover {
        color: #343A40;
        background: #F5F6F7;
        border: 1px dashed #343A40;

        .add-icon {
          fill: #343A40;
        }
      }

      .add-icon {
        margin-right: 8px;
      }

      .add-title {
        text-align: center;
        border: none;
        outline: none;
        background: transparent;
      }
    }
  }

  .editor-box {
    flex: 1 1;
  }

}