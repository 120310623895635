.container {
  position: relative;
  width: 1000px;

  > .itemGroup {
    margin-top: 48px;

    > h4 {
      border-bottom: 1px solid black;
      padding-bottom: 8px;
    }

    > .item {
      display: flex;
      align-items: center;

      > .label {
        width: 128px;
        font-size: 14px;
        flex-shrink: 0;

        > strong {
          color: red;
        }
      }

      > .value {
        position: relative;
        width: 256px;

        > .inputWithToggle {
          display: flex;
          column-gap: 32px;
        }
      }

      & + .item {
        margin-top: 32px;
      }
    }
  }
}



.saveButton {
  margin-top: 32px;
}

.description-value {
  width: unset;
  flex-grow: 1;
}

.textarea {
  width: 100%;
  padding: 14px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #d7dbdf;
  resize: vertical;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.error {
  position:absolute;
  font-size: 14px;
  color: red;
  bottom: -20px;
}

.footer {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.total {
  display: flex;
}

.total-row {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;

  > .value {
    width: 150px;
    input {
      text-align: right;
    }
  }
}