.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;
  background: #ced4da;
  border-radius: 40px;
  padding: 2px;
  cursor: pointer;
  transition: all 0.4s;

  > span {
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: white;
    transition: all 0.4s;
    left: 2px;
  }

  &:hover:not(.disabled) {
    background-color: #adb5bd;
  }

  &.on {
    background-color: #12b886;

    &:hover:not(.disabled) {
      background-color: #41ac4d;
    }

    > span {
      left: 20px;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
