@font-face {
  font-family: Pretendard;
  font-weight: 900;
  font-display: swap;
  src: url('./asset/font/Pretendard-Black.woff') format('woff');
}

@font-face {
  font-family: Pretendard;
  font-weight: 800;
  font-display: swap;
  src: url('./asset/font/Pretendard-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: Pretendard;
  font-weight: 700;
  font-display: swap;
  src: url('./asset/font/Pretendard-Bold.woff') format('woff');
}

@font-face {
  font-family: Pretendard;
  font-weight: 600;
  font-display: swap;
  src: url('./asset/font/Pretendard-SemiBold.woff') format('woff');
}

@font-face {
  font-family: Pretendard;
  font-weight: 500;
  font-display: swap;
  src: url('./asset/font/Pretendard-Medium.woff') format('woff');
}

@font-face {
  font-family: Pretendard;
  font-weight: 400;
  font-display: swap;
  src: url('./asset/font/Pretendard-Regular.woff') format('woff');
}

@font-face {
  font-family: Pretendard;
  font-weight: 300;
  font-display: swap;
  src: url('./asset/font/Pretendard-Light.woff') format('woff');
}

@font-face {
  font-family: Pretendard;
  font-weight: 200;
  font-display: swap;
  src: url('./asset/font/Pretendard-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: Pretendard;
  font-weight: 100;
  font-display: swap;
  src: url('./asset/font/Pretendard-Thin.woff') format('woff');
}


body {
  margin: 0;
}

* {
  font-family: Pretendard;
  box-sizing: border-box;
}

.modal-scroll {
  &::-webkit-scrollbar {
    width: 15px;
    height: 15px;
    border: 3px solid transparent;
  }

  &::-webkit-scrollbar-button:start:decrement,
  &::-webkit-scrollbar-button:end:increment {
    display: block;
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    height: 10px;
    width: 10px;
    background: rgb(0 0 0 / 20%);
    border-radius: 8px;
    border-radius: 8px;
    box-shadow: inset 0 0 4px transparent;
    background-clip: padding-box;
    border: 5px solid transparent;
  }
}

body {
  width: 100%;
  min-height: 100%;
}

.react-datepicker__aria-live {
  display: none;
}