.container {
  min-width: 100vw;
  min-height: 100vh;

  .align {
    display: flex;
    height: calc(100% - 50px);

    .sidebar {
      position: fixed;
      inset: 0;
      display: flex;
      flex-direction: column;
      width: 200px;
      padding-top: 20px;
      border-right: 1px solid #F5F6F7;
      background-color: #F5F6F7;
      z-index: 10;

      .logo {
        width: 100%;
        min-height: 30px;
        height: 30px;
        display: block;
        background: url('../../../asset/img/ats_header.png') no-repeat;
        background-size: 30px;
        background-position-x: 20px;
        padding: 20px;
      }

      .depth1 {
        list-style: none;
        margin: 0;
        padding: 0;

        > li {
          width: 100%;
          margin-bottom: 4px;
          overflow: hidden;
          transition: all 0.3s;

          &.nomargin {
            margin-bottom: 0;
          }

          &.active {
            > a {
              background: #f5f6f7;
            }

            .depth2 {
              max-height: 135px;
            }
          }

          > a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-decoration: none;
            width: 100%;
            height: 100%;
            padding: 10.5px 10px;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #343A40;
            cursor: pointer;
            border-radius: 4px;
            user-select: none;

            &.margin {
              margin-bottom: 4px;
            }

            .left {
              display: flex;
              align-items: center;

              > svg {
                margin-right: 8px;
              }
            }

            &:hover {
              background: #f5f6f7;
            }
          }
        }
      }

      .depth2 {
        list-style: none;
        padding: 0;
        margin: 0;
        max-height: 0;
        transition: all 0.3s linear;
        overflow: hidden;

        > li {
          width: 100%;

          &.margin {
            margin-bottom: 5px;
          }

          &.active > a {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #343A40;
          }

          &:hover a {
            color: #343A40;
          }

          > a {
            display: flex;
            align-items: center;
            padding: 6px 0 6px 41px;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #adb5bd;
            cursor: pointer;
            text-decoration: none;
          }
        }
      }
    }

    .content {
      position: fixed;
      left: 200px;
      width: calc(100% - 200px);
      padding: 0 20px 20px 50px;
      height: calc(100% - 50px);
      overflow: auto;
    }
  }
}