.composition-table {
  width: 100%;
  font-size: 14px;
  border-collapse: collapse;

  > thead > tr {
    border-bottom: 1px solid lightgray;

    > th {
      padding: 8px 0;

      &:first-child {
        text-align: left;
      }
    }
  }

  td {
    height: 40px;
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &.no-data {
      text-align: center;
    }
  }
}