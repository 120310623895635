.toggle-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &.red {
    color: red;
  }
}

.price {
  width: 130px;
  text-align: center;
}

.ncc-radio-wrap {
  display: flex;
  align-items: center;
  gap: 24px;

  > label {
    cursor: pointer;
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

.ncc-row {
  > td:first-child {
    padding-left: 32px;
  }

  &.disabled {
    color: lightgrey;
  }

  &.end {
    border-bottom: 1px solid #eee;
  }
}

.align-right {
  text-align: right;
}