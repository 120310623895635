.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 700;
  background: rgb(30 30 36 / 40%);

  &.scroll {
    overflow-y: auto;
  }
}

.content {
  max-height: calc(100% - 40px);
  padding: 40px;
  background: white;
  border-radius: 16px;
}
