.container {
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #343A40;
    padding: 0;
    margin-bottom: 30px;
  }

}