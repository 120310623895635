.button {
  color: #FFF;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 10px;
  background: #12B886;
  border: 1px solid #12B886;
  border-radius: 4px;
  outline: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    background: #067a6f;
  }
}