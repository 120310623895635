.container {
  width: 660px;
  padding-left: 1px;

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #343A40;
    margin: 28px 0 48px;
  }

  .field {
    margin-bottom: 48px;

    //참여기업 및 포지션 정보
    .info {
      display: flex;
      align-items: center;

      .participatingCompanyStatus {
        width: 200px;
        margin-right: 5px;
      }
    }

    //해시태그
    .hash-tag {
      display: flex;
      flex-direction: column;

      .top {
        display: flex;

        &.margin {
          margin-bottom: 16px;
        }

        > input {
          width: 540px;
        }

        > button {
          margin-left: 5px;
        }
      }

      .bottom {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        .keyword {
          display: flex;
          align-items: center;
          padding: 10px 22px;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #343A40;
          background: #F1F3F5;
          border-radius: 300px;

          .remove-hash {
            width: 18px;
            height: 18px;
            background: url("../../../../asset/icon/icon_close_18_line.svg") no-repeat;
            background-position: center;
            margin-left: 3px;
            border: none;
            outline: none;
            cursor: pointer;
          }
        }
      }
    }

    .openYn {
      position: relative;
      display: flex;
      align-items: center;
      width: 236px;

      &.limit::before {
        position: absolute;
        content: "제한없음";
        left: 20px;
      }

      .limitCheck {
        margin-left: 5px;
      }

      .limitYn {
        width: 100px;
        margin-left: 5px;
      }
    }

    // 접수기간
    .time {
      display: flex;
      align-items: center;

      .hyphen {
        position: relative;
        width: 20px;
        height: 100%;

        &::before {
          content: "";
          position: absolute;
          width: 6px;
          height: 1px;
          left: 7px;
          border-radius: 100%;
          background: #343A40;
        }
      }
    }

    //접수기간
    .picker {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #343A40;
      padding: 14px;
      border: 1px solid #CED4DA;
      border-radius: 6px;
      width: 150px;
      margin-right: 5px;
    }

    //배너이미지
    .banner-img {
      .desc {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #343A40;
        margin: 0 0 16px;
      }
    }

    .color-collection {
      display: flex;

      .picker-wrapper {
        position: relative;

        .preview-color {
          position: absolute;
          top: 10px;
          right: 10px;
          width: 30px;
          height: 30px;
          border-radius: 4px;
        }
      }

      .half {
        width: 40%;

      }
    }
  }

        .error {
          display: inline-block;
          margin-left: 8px;
          font-size: 11px;
          color: red;
        }
}