.button {
  padding: 10px 26px;
  outline: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #868E96;
  cursor: pointer;
  background: #FFF;
  border: 1px solid #868E96;
  border-radius: 4px;
}