.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(120deg, #cee7fe, #eefadd);

  .border-box {
    display: flex;
    flex-direction: column;
    min-width: 320px;
    border-radius: 20px;
    margin-bottom: 40px;
    overflow: hidden;
    background: white;
    box-shadow: 0 10px 15px rgb(0 0 0 / 10%), 0 4px 6px rgb(0 0 0 / 5%);
    transition: height .3s linear;

    &.login {
      height: 250px;
    }

    &.signup {
      height: 500px;
    }

    .tab {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      list-style: none;
      width: 100%;
      height: 50px;
      min-height: 50px;

      .signup-tab {
        cursor: not-allowed;
      }

      >li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        cursor: pointer;

        &.active::before {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          color: rgb(87 70 175);
          border-bottom: 2px solid rgb(87 70 175);
        }
      }
    }

  }
}