.wrapper {
  position: relative;
}

.input {
  position: relative;
  width: 100%;
  height: 48px;
  padding: 0 14px;
  transition: all .3s;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 0 0 1px #d7dbdf;
  border: none;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;

  &.error:not(:disabled):not(:read-only) {
    box-shadow: 0 0 0 1px red;
  }

  &:not(:read-only) {
    &:focus, &:hover {
      box-shadow: 0 0 0 1px #5eb0ef;
    }
  }

  &:read-only {
    background-color: #F4F4F5;
    cursor: default;
  }

  &:disabled {
    box-shadow: none;
    background-color: #F4F4F5;
    color: gray;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}

.error-message {
  position: absolute;
  font-size: 14px;
  color: red;
  bottom: -20px;
  width: max-content;
}