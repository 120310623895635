.click-area {
  position: relative;

  > .dropdown {
    position: absolute;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    top: 100%;
    z-index: 1000;
    background: white;
    border: 1px solid #DEE2E6;
    border-radius: 8px;
    padding: 10px 5px;
    width: max-content;
    user-select: none;

    &.visible {
      display: flex;
    }

    &.state-changer {
      left: 50%;
      transform: translateX(-50%);

      > div {
        justify-content: center;
        padding: 0 16px;
      }
    }

    > div {
      padding: 0 40px 0 4px;
      display: flex;
      gap: 8px;
      align-items: center;
      color: #212529;
      height: 32px;
      width: 100%;
      cursor: pointer;

      &:hover {
        background: #F8F9FA;
      }

      > .checkbox {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 20px;
        min-height: 20px;
        width: 20px;
        height: 20px;
        background: #fff;
        border: 1px solid #ced4da;
        box-sizing: border-box;
        vertical-align: top;
        border-radius: 4px;
        cursor: pointer;

        > svg {
          fill: white;
          transition: all 0.4s;
        }

        &.checked {
            background-color: #12b886;
            border-color: #12b886;
            background-image: url("../../../asset/img/checked.svg");
            background-position: center;
            background-repeat: no-repeat;

          &:hover {
            + .label {
              background-color: #0ca678;
              border-color: #0ca678;
              box-shadow: none;

              > svg {
                fill: white;
              }
            }
          }

          &:focus {
            + .label {
              border-color: #41ac4d;
            }
          }

          &:disabled {
            + .label {
              background-color: #e9ecef;
              border-color: #e9ecef;
              box-shadow: none;

              > svg {
                fill: #adb5bd;
              }
            }
          }
        }
      }
    }
  }
}