.left {
  margin-right: 96px;

  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #353535;
    margin: 28px 0 42px;
  }

  .step-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;

    .step {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 224px;
      height: 52px;
      padding: 16px 24px;
      background: #F5F6F7;
      border-radius: 200px;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 8px;
      color: #ADB5BD;
      cursor: pointer;
      transition: .4s all;

      &.disabled {
        cursor: not-allowed;

        &:hover {
          background: #F5F6F7;
          color: #ADB5BD;

          .step-left .icon {
            fill: #ADB5BD;
          }
        }
      }

      &.active, &:hover {
        background: #12B886;
        color: white;

        .step-left .icon {
          fill: white;
        }
      }

      .step-left {
        display: flex;
        align-items: center;

        .icon {
          position: relative;
          width: 24px;
          height: 24px;
          margin-right: 10px;
          fill: #ADB5BD;
        }
      }
    }
  }
}

.dots {
  display: inline-flex;
  height: 24px;
  align-items: center;
  gap: 3px;
  margin-left: 8px;

  > span {
    display: inline-flex;
    background: white;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    animation: dots-center-animation 2s ease-in-out infinite;

    &:first-child {
      animation: dots-left-animation 2s ease-in-out infinite;
    }

    &:last-child {
      animation: dots-right-animation 2s ease-in-out infinite;
    }
  }
}

@keyframes dots-left-animation {
  0% {
    opacity: 0.2;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  75% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes dots-center-animation {
  0% {
    opacity: 0.2;
  }

  25% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes dots-right-animation {
  0% {
    opacity: 0.2;
  }

  25% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.2;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}