.wrap {
  width: 108px;
  position: relative;

  .icon {
    position: absolute;
    top: 5px;
    left: 4px;
    z-index: 1;
  }

  .input {
    padding-left: 36px;
    height: 36px;
    font-size: 14px;

    &:read-only:not(:disabled) {
      background: unset;
    }

  }
}