.content {
  width: 416px;
  text-align: center;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px 38px;

    .icon-lock {
      width: 64px;
      height: 64px;
      background: url('../../../asset/img/icon_lock_open.png') no-repeat;
      background-size: 64px;
    }

    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #343A40;
      margin: 30px 15px 15px;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #343A40;
      margin: 0 0 44px 0;
    }

    .re-login {
      width: 100%;
    }
  }
}
