.container {
  flex: 1 1;
  padding-bottom: 20px;

  .table {
    width: 100%;
    text-align: center;
    font-size: 14px;
    border-collapse: initial;
    border-spacing: 0 10px;

    .row {
      position: relative;
      transition: all 0.4s;
      cursor: pointer;

      &:hover {
        background: #effdf3;
        border-radius: 8px;
        box-shadow: 0 0 0 1px #52c564;
        cursor: pointer;

        td {
          position: relative;
          border-color: #52c564 !important;

          &.internalName {
            text-decoration: underline;
          }

          .icon.plus {
            background-color: #effdf3;
            border-color: #41ac4d;
            color: #41ac4d;

            svg {
              fill: #41ac4d;
            }
          }
        }
      }

      > td {
        border-top: 1px solid #dbdde0;
        border-bottom: 1px solid #dbdde0;
        padding: 16px 0;
        transition: all 0.4s;

        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          border-left: 1px solid #dbdde0;
        }

        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border-right: 1px solid #dbdde0;
        }


        &.closingTime {
          &.diff {
            color: #12B886;
          }
        }

        &.positionName {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .header {
      color: #9fa4ab;

      > tr > th {
        font-weight: 500;

        .sort-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #868E96;
          background: transparent;
          border: none;
          outline: none;
          cursor: pointer;
        }
      }
    }
  }

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #F8F9FA;
    border-radius: 8px;
    color: #343A40;
    margin-bottom: 20px;

    .img {
      position: relative;
      width: 100px;
      height: 100px;
      display: block;
      background: url('../../../asset/img/icon_page_list_user_mono.svg');
    }

    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #343A40;
      margin-bottom: 8px;
    }

    .desc {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #495057;
    }
  }
}