.alert {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: .3;
  z-index: 1000;
}

.body {
  position: absolute;
  left: calc(50% - 200px);
  top: calc(50% - 60px);
  width: 400px;
  height: 120px;
  background: white;
  border-radius: 20px;
  z-index: 1001;
  padding: 20px;
  box-shadow: rgb(14 18 22 / 35%) 0 10px 38px -10px, rgb(14 18 22 / 20%) 0 10px 20px -15px;
}