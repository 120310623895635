.wrap {
  display: flex;
  flex-direction: column;
  width: 60px;
  border: 1px solid #dee2e6;
  border-radius: 8px;

  > button {
    background: none;
    border: none;
    cursor: pointer;

    &:active {
      background: #dee2e6;
    }

    &:last-child {
      transform: rotate(180deg);
    }
  }

  .number {
    outline: none;
    border: none;
    padding: 0 !important;
    text-align: center;
    height: 32px;
    border-radius: 0;
    font-size: 14px;
  }
}