.container {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;

  .left {
    display: flex;
    align-items: center;
    color: #656a71;
    font-size: 14px;
    user-select: none;

    > svg {
      margin-right: 10px;
    }
  }

  .middle {
    display: flex;
    justify-content: center;
    align-items: center;

    .pagination {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;

      > li {
        width: 32px;
        height: 32px;
        border-radius: 10px;
        margin-right: 5px;
        color: #656a71;
        font-size: 14px;
        text-align: center;
        line-height: 32px;
        cursor: pointer;

        &:last-child {
          margin: 0;
        }

        &.active,
        &:hover {
          color: #52c564;
          background-color: #effdf3;
          border-radius: 16px;
        }
      }
    }

    .btn {
      width: 32px;
      height: 32px;
      border-radius: 16px;
      border: none;
      outline: none;

      &.active,
      &:hover {
        color: #121619;
        background-color: #effdf3;
        border-radius: 16px;
        cursor: pointer;

        &.possible > svg {
          fill: #121619;
        }
      }

      &:disabled {
        > svg {
          fill: #9fa4ab;
        }

        &.active,
        &:hover {
          cursor: not-allowed;
          background-color: transparent;
        }
      }

      &.prev {
        background-image: url('../../../asset/img/down_simple.svg');
        background-repeat: no-repeat;
        background-size: 18px;
        background-position: center;
        transform: rotate(90deg);
        margin-right: 10px;
      }

      &.next {
        background-image: url('../../../asset/img/down_simple.svg');
        background-size: 18px;
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(270deg);
        margin-left: 10px;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    user-select: none;

    .margin {
      margin-right: 10px;
    }

    .select {
      width: 75px;
      font-weight: 400;
    }
  }
}
