@mixin font($variant, $bold: false, $no-line-height: false) {
  @if $variant == 'h1' {
    font-size: 48px;
    line-height: 62px;
    font-weight: 700;
  }

  @if $variant == 'h2' {
    font-size: 32px;
    line-height: 42px;
    font-weight: 700;
  }

  @if $variant == 'h3' {
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
  }

  @if $variant == 'h4' {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }

  @if $variant == 'h6' {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }

  @if $variant == 'subtitle' {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
  }

  @if $variant == 'b1' {
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;

  }

  @if $variant == 'b2' {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;

  }

  @if $variant == 'b3' {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;

  }

  @if $variant == 'b4' {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
  }

  @if $variant == 'b5' {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
  }

  @if $variant == 'caption_b' {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
  }

  @if $variant == 'caption_m' {
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
  }

  @if $variant == 'ATSbuilder_h1' {
    font-size: 64px;
    line-height: 84px;
    font-weight: 700;
  }

  @if $variant == 'ATSbuilder_h2' {
    font-size: 48px;
    line-height: 62px;
    font-weight: 700;
  }

  @if $variant == 'ATSbuilder_h3' {
    font-size: 32px;
    line-height: 46px;
    font-weight: 700;
  }

  @if $variant == 'ATSbuilder_h4' {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
  }

  @if $variant == 'ATSbuilder_p1' {
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
  }

  @if $variant == 'ATSbuilder_p2' {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
  }

  @if $variant == 'ATSbuilder_p3' {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }

  @if $bold {
    font-weight: 700;
  }

  @if $no-line-height {
    line-height: unset;
  }
}
