.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 250px);

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;

    .img {
      width: 96px;
      height: 106px;
      background: url('../../../asset/img/empty_acc_result.svg') no-repeat;
      margin-bottom: 20px;
      font-weight: bold;
    }
  }
}