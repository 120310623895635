.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: linear-gradient(120deg, #f8d8e7, #cfe6fd);
  opacity: .2;
}

.spinner {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #3498db;
  animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */

  &.small {
    width: 32px;
    height: 32px;
    margin: -16px 0 0 -16px;
  }
}

.spinner::before {
  content: "";
  position: absolute;
  inset: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e74c3c;
  animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

.spinner::after {
  content: "";
  position: absolute;
  inset: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #f9c922;
  animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

@keyframes spin {
  0%   {
    transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    transform: rotate(0deg);  /* IE 9 */
    transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
  }

  100% {
    transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    transform: rotate(360deg);  /* IE 9 */
    transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
  }
}

@keyframes spin {
  0%   {
    transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    transform: rotate(0deg);  /* IE 9 */
    transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
  }

  100% {
    transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    transform: rotate(360deg);  /* IE 9 */
    transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
  }
}