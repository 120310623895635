.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  .field {
    width: 100%;
    display: flex;
    align-items: center;

    &.block {
      width: 100%;
    }
  }

  .name {
    display: flex;
    align-items: flex-start;
    min-width: 125px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #343A40;

    &.required::after {
      content: '*';
      color: red;
    }
  }

  .textarea {
    width: 100%;
    height: 100px;
    resize: none;
    background: #FFF;
    border: 1px solid #E7E7E7;
    border-radius: 4px;
    padding: 14px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #343a40;
    outline: none;

    &::placeholder {
      color: #848991;
    }
  }

  .margin-left {
    margin-left: 8px;
  }

  &.has-error {
    margin-bottom: 0;
  }
}

.error {
  margin-top: 4px;
  margin-left: 125px;
  margin-bottom: 16px;
}