.container {
  padding: 65px 20px 20px 50px;
  height: 100%;

  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #343A40;
    padding-bottom: 49px;
    margin: 0 0 24px;
    border-bottom: 1px solid #EAEBEE;
  }

  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 36px;
    margin-bottom: 24px;

    .search {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 18px;
        height: 18px;
        top: 9px;
        left: 12px;
        background: url('../../../asset/icon/16_search.svg') no-repeat;
        z-index: 1;
      }

      >input {
        position: relative;
        width: 248px;
        height: 36px;
        background: #FFF;
        border: 1px solid #DEE2E6;
        border-radius: 4px;
        outline: none;
        padding-left: 40px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #343A40;

        &::placeholder {
          color: #868E96;
        }
      }
    }

    .create {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      background-color: #12B886;
      border: #12B886;
      border-radius: 4px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #FFF;
      outline: none;
      background-image: url('../../../asset/icon/icon_add_18_line.svg');
      background-repeat: no-repeat;
      background-position: 12px;
      cursor: pointer;

      svg {
        margin-right: 8px;
      }
    }
  }
}