.modal {
  max-height: calc(100% - 40px);
  padding: 0;
  background: none;

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #212529;
    padding: 40px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    .title {
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      color: white;
    }

    .btn-group {
      display: flex;
      align-items: center;

      .btn {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        padding: 10px 26px;
        margin-right: 8px;
        outline: none;
        border: none;
        cursor: pointer;
        border-radius: 4px;
        color: #FFF;

        &.cancel {
          background: transparent;
        }

        &.save {
          background: #12B886;
        }
      }

      .save {

      }
    }

    .close {
      cursor: pointer;
    }
  }

  .modal-body {
    width: 680px;
    height: 450px;
    border-radius: 0 0 16px 16px;
    overflow: hidden;
  }
}
