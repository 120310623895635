.position-list {
  position: absolute;
  background: white;
  border: 1px solid #e7e8ea;
  box-sizing: border-box;
  box-shadow: 0 10px 15px rgb(0 0 0 / 10%), 0 4px 6px rgb(0 0 0 / 5%);
  border-radius: 6px;
  padding: 10px 6px;
  z-index: 800;
  width: 432px;
  max-height: 400px;
  overflow: auto;

  .position {
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #656a71;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.4s;
    align-items: center;
    padding-left: 6px;

    &:hover {
      background: #f4f4f5;
      color: #25282b;
    }
  }

  .label {
    display: block;
    height: 100%;
    width: 100%;
    margin-left: 5px;
    padding: 6px 10px;
  }
}