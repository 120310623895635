.container {
  position: relative;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 256px;
  height: 62px;
  background-color: #343A40;
  padding: 0 45px;
  border-radius: 8px 8px 0px 0px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    border: none;
    background: none;
    outline: none;
    padding: 0;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      opacity: .5;
    }
  }

  .middle {
    display: flex;
    flex-direction: column;
    user-select: none;
    width: 100%;

    .year {
      text-align: center;
      font-size: 11px;
      line-height: 14px;
      color: #FFFFFF;
      cursor: pointer;
    }

    .month {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
}