@import '../../../fonts';

.modal-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .modal-title {
    @include font(h6);
  }

  .modal-description {
    @include font(b2, true, true);

    line-height: 14px;
    text-align: center;
    margin: 15px 50px 0;
  }

  .modal-confirm {
    width: 72px;
    margin-left: auto;
  }
}