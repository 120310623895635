.container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 170px);

  .table {
    width: 100%;
    text-align: center;
    font-size: 14px;
    border-collapse: initial;
    border-spacing: 0 10px;

    .row {
      position: relative;
      transition: all 0.4s;
      cursor: pointer;

      &:hover {
        background: #effdf3;
        border-radius: 8px;
        box-shadow: 0 0 0 1px #52c564;
        cursor: pointer;

        td {
          position: relative;
          border-color: #52c564 !important;

          &.internalName {
            text-decoration: underline;
          }

          .icon.plus {
            background-color: #effdf3;
            border-color: #41ac4d;
            color: #41ac4d;

            svg {
              fill: #41ac4d;
            }
          }
        }
      }

      > td {
        border-top: 1px solid #dbdde0;
        border-bottom: 1px solid #dbdde0;
        padding: 22px 0;
        transition: all 0.4s;

        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          border-left: 1px solid #dbdde0;
        }

        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border-right: 1px solid #dbdde0;
        }

        &.status {
           .wrapper {
             display: flex;
             align-items: center;
             justify-content: center;

             .circle {
               width: 8px;
               height: 8px;
               margin-right: 8px;
               border-radius: 100%;

               &.START {
                 background: #40C057;
               }

               &.END {
                 background: #adb5bd;
               }

               &.EDITING {
                 background: #15AABF;
               }

               &.READY {
                 background: #82C91E;
               }
             }
           }
        }

        &.more {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 20px;
            width: 1px;
            height: 32px;
            background: #F1F3F5;
          }

          .more-btn {
            position: relative;
            border: none;
            outline: none;
            background: none;
            padding: 0;
            width: 32px;
            height: 32px;
            cursor: pointer;
            border-radius: 4px;
            z-index: 100;
            transition: .4s all;

            &:hover {
              background-color: #e7e8ea;
            }
          }
        }
      }
    }

    .header {
      color: #9fa4ab;

      > tr > th {
        font-weight: 500;

        .sort-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #868E96;
          background: transparent;
          border: none;
          outline: none;
          cursor: pointer;
        }
      }
    }
  }
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #F8F9FA;
  border-radius: 8px;
  color: #343A40;
  margin-bottom: 20px;

  .img {
    position: relative;
    width: 100px;
    height: 100px;
    display: block;
    background: url('../../../asset/img/icon_page_list_user_mono.svg');
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #343A40;
    margin-bottom: 8px;
  }

  .desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #495057;
  }
}