.button {
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 4px;
    background: #52C564;
    cursor: pointer;
    padding: 0 26px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.8;
    }

    &.secondary {
        background: #F4F4F5;
        color: #25282B;
    }

    &.small {
        padding: 8px 12px;
    }

    &.middle {
        font-size: 14px;
        height: 40px;
    }

    &.large {
        height: 48px;
    }

    &.red-line {
        color: red;
        border: 1px solid red;
        background: none;
    }

    &.red {
        background-color: red;
        border: red;

        &:disabled {
            opacity: 0.3;
            cursor: not-allowed;
        }
    }

    &.black {
        color: #FFF;
        background: #343A40;
    }

    &.gray-line {
        background: white;
        border: 2px solid #848991;
        color: #848991;

        &:hover {
            background: #eee;
        }
    }

    &.wide {
        width: 100%;
    }
}

.loader {
    border: 2px solid white;
    border-top: 2px solid transparent;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}