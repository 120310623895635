.container {
  display: flex;
  flex-direction: column;

  .title {
    position: relative;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #343A40;
    margin-bottom: 10px;

    &.active > span {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        top: 0;
        right: -9px;
        border-radius: 100%;
        background-color: #12B886;
      }
    }
  }
}