.box {
  padding: 40px;
  width: 1400px;
  border: 1px solid #DEE2E6;
  border-radius: 16px;


  .sub-box {
    margin-bottom: 56px;

    .sub-title {
      display: block;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #343A40;
      margin-bottom: 24px;
      padding-bottom: 4px;
      border-bottom: 1px solid black;
    }

    .picker {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #343A40;
      padding: 14px;
      border: 1px solid #CED4DA;
      border-radius: 6px;
      width: 150px;
      margin-right: 5px;
    }

    .textarea {
      width: 100%;
      height: 100px;
      resize: none;
      background: #FFF;
      border: 1px solid #E7E7E7;
      border-radius: 4px;
      padding: 14px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #343a40;
      outline: none;

      &::placeholder {
        color: #848991;
      }

      &:disabled {
        background-color: #F4F4F5;
      }
    }

    .margin-left {
      margin-left: 8px;
    }
  }
}

.select {
  width: 243px !important;
  z-index: 1;

  &.second {
    z-index: 0;
  }
}

.composition-table {
  width: 100%;
  padding-bottom: 4px;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}

.attach-wrap {
  display: flex;

  > .file-list {
    flex-grow: 1;
    list-style: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    > li {
      display: flex;
      gap: 8px;
      align-items: center;

      > span {
        color: #5E9EFF;
        text-decoration: underline;
        cursor: pointer;
      }

      > .delete {
        width: 16px;
        height: 16px;
        background: lightgray;
        border-radius: 10px;
        position: relative;
        transform: rotate(45deg);
        cursor: pointer;

        &::before {
          content: "";
          border-radius: 1px;
          position: absolute;
          width: 8px;
          height: 2px;
          top: 7px;
          left: 4px;
          background: white;
        }

        &::after {
          content: "";
          border-radius: 1px;
          position: absolute;
          width: 2px;
          height: 8px;
          top: 4px;
          left: 7px;
          background: white;
        }

        &:hover {
          background: crimson;
        }
      }

      & + li {
        margin-top: 8px;
      }
    }
  }
}

.business-number-error {
  margin-left: 12px;
  color: red;

  &::before {
    content: "*";
  }
}

.applicants {
  display: flex;
  gap: 8px;
  align-items: center;

  > .infinite {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}

.input {
  width: 243px;
}

.business-input {
  display: flex;
}

.verify-modal-container {
  text-align: center;

  > .title {
    font-weight: 700;
    font-size: 20px;
  }

  > .sub {
    margin-top: 16px;
  }

  > .buttons {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}

.contract-period {
  display: flex;
  align-items: center;
}

.caution {
  padding: 16px;
  background: pink;

  > .agree-wrap {
    margin-top: 16px;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
}