.picture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 655px;
  height: 450px;
  border: 1px dashed #ADB5BD;
  border-radius: 4px;
  cursor: pointer;


  .add-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    border-radius: 100%;
    border: 1px solid #CED4DA;
  }
}

.wrapper {
  width: 655px;
  background-size: cover;
}


.file-input {
  display: none;
}