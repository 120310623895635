.toolbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 36px;
  margin-bottom: 24px;
  gap: 16px;

  > .date-wrap {
    display: flex;
    align-items: center;
    gap: 4px;

    > .checkbox {
      user-select: none;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .search {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 18px;
      height: 18px;
      top: 9px;
      left: 12px;
      background: url('../../../asset/icon/16_search.svg') no-repeat;
      z-index: 1;
    }

    input {
      position: relative;
      width: 360px;
      height: 36px;
      background: #FFF;
      border: 1px solid #DEE2E6;
      border-radius: 4px;
      outline: none;
      padding-left: 40px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #343A40;

      &::placeholder {
        color: #868E96;
      }
    }
  }

  .button-wrap {
    display: flex;
    gap: 8px;

    .create {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      background-color: #12B886;
      border: #12B886;
      border-radius: 4px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #FFF;
      outline: none;
      background-image: url('../../../asset/icon/icon_add_18_line.svg');
      background-repeat: no-repeat;
      background-position: 12px;
      cursor: pointer;

      svg {
        margin-right: 8px;
      }
    }

    .delete {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      background-color: red;
      border: red;
      border-radius: 4px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #FFF;
      outline: none;
      background-image: url('../../../asset/icon/icon_add_18_line.svg');
      background-repeat: no-repeat;
      background-position: 12px;
      cursor: pointer;

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }
}

.column-group > td {
  background: #F7F7F8;
  border-right: 10px solid white;
  padding: 5px 0;

  & + td {
    border-left: 10px solid white;
  }

  &:first-child {
    background: none;
  }

  &:last-child {
    border-right: none;
  }
}

.filter {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}