.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background-color: white;
  z-index: 1;
  gap: 10px;

  .right {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    gap: 30px;

    .session {
      display: flex;
      align-items: center;
      gap: 10px;

      .session-timer {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .user {
      display: flex;
      align-content: center;
      font-weight: 700;
      font-size: 14px;
      gap: 10px;
      margin-right: 20px;

      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        background: #4caf50;
        color: white;
      }

      .name{
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}