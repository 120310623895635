.container {
  width: 100%;
  height: 100%;
  min-width: 660px;

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #343A40;
    margin: 28px 0 48px;
  }

  .position-list {
    list-style: none;
    margin: 0;
    padding: 0;

    .position {
      display: flex;
      justify-content: space-between;
      padding: 25px 16px;
      border: 1px solid #DEE2E6;
      border-radius: 6px;
      cursor: pointer;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {

      }

      .left {
        display: flex;
        align-items: center;

        .handler {
          display: block;
          width: 24px;
          height: 24px;
          margin-right: 16px;
        }

        .name {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          margin-left: 16px;
          white-space: nowrap;
          max-width: 380px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .right {
        display: flex;
        align-items: center;

        > span {
          position: relative;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          color: #343A40;
          margin-right: 32px;

          &:last-child {
            margin-right: 0;

            &::before {
              width: 0;
              height: 0;
            }
          }

          &::before {
            position: absolute;
            content: "";
            right: -16px;
            width: 1px;
            height: 16px;
            background-color: #DEE2E6;
          }
        }
      }
    }
  }
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #F8F9FA;
  border-radius: 8px;
  color: #343A40;
  margin-bottom: 20px;

  .img {
    position: relative;
    width: 100px;
    height: 100px;
    display: block;
    background: url('../../../../asset/img/icon_page_list_user_mono.svg');
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #343A40;
    margin-bottom: 8px;
  }

  .desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #495057;
  }

}