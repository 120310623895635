.input {
  &:checked + .radio {
    box-shadow: none;
    border: 5px solid #0CA678;

    &:hover {
      border-color: #0CA678;
    }

    &:focus {
      outline-color: #C3FAE8FF
    }
  }

  &:disabled {
    & + .radio {
      cursor: not-allowed;
      background: #F1F3F5FF;
      box-shadow: none;
      border: none;
    }

    &:checked + .radio {
      border: 5px solid #F1F3F5FF;
      background: #CED4DAFF;
    }
  }
}

.radio {
  border: 2px solid #CED4DAFF;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:focus {
    outline: 2px solid #E9ECEFFF;
  }
}