.container {
  position: relative;
  width: 1000px;

  > .itemGroup {
    margin-top: 48px;

    > h4 {
      border-bottom: 1px solid black;
      padding-bottom: 8px;
    }

    > .item {
      display: flex;

      > .label {
        height: 46px;
        display: flex;
        align-items: center;
        width: 128px;
        font-size: 14px;
        flex-shrink: 0;

        > strong {
          color: red;
        }
      }

      > .value {
        position: relative;

        > .inputWithToggle {
          display: flex;
          column-gap: 32px;
        }
      }

      & + .item {
        margin-top: 32px;
      }
    }
  }
}



.saveButton {
  margin-top: 32px;
}

.description-value {
  width: unset;
  flex-grow: 1;
}

.textarea {
  width: 100%;
  padding: 14px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #d7dbdf;
  resize: vertical;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.error {
  position:absolute;
  font-size: 14px;
  color: red;
  bottom: -20px;
}

.footer {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.total {
  display: flex;
}

.total-row {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;

  > .value {
    input {
      text-align: right;
    }
  }
}

.picker {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #343A40;
  padding: 12px;
  border: 1px solid #CED4DA;
  border-radius: 6px;
  width: 120px;
  margin-right: 5px;
}

.time {
  width: 130px;
}

.flex {
  display: flex;
  width: unset !important;
  align-items: center;
  > span {
    margin: 0 8px;
  }
}

.attach-wrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.image {
  width: unset !important;
}

.popup-image {
  width: 400px;
  height: 400px;
}

.stop-viewing-wrap {
  height: 46px;
  display: flex;
  align-items: center;
  gap: 24px;

  > label {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 4px;
  }
}

.error-message {
  position: absolute;
  font-size: 14px;
  color: red;
  bottom: -20px;
  width: max-content;
}