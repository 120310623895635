.container {
  flex: 1 1;
  padding-bottom: 10px;
  overflow: auto;

  .table {
    width: 100%;
    text-align: center;
    font-size: 14px;
    border-collapse: initial;
    border-spacing: 0 10px;

    .row {
      position: relative;
      transition: all 0.4s;
      cursor: pointer;

      &:hover {
        background: #effdf3;
        border-radius: 8px;
        box-shadow: 0 0 0 1px #52c564;
        cursor: pointer;

        td {
          position: relative;
          border-color: #52c564 !important;

          &.internalName {
            text-decoration: underline;
          }

          .icon.plus {
            background-color: #effdf3;
            border-color: #41ac4d;
            color: #41ac4d;

            svg {
              fill: #41ac4d;
            }
          }
        }
      }

      > td {
        border-top: 1px solid #dbdde0;
        border-bottom: 1px solid #dbdde0;
        padding: 16px 0;
        transition: all 0.4s;

        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          border-left: 1px solid #dbdde0;
        }

        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border-right: 1px solid #dbdde0;
        }

        &.positionName {
          min-width: 210px;
          max-width: 310px;
          text-align: left;

          .name-group {
            display: flex;
            align-items: center;

            .name {
              max-width: 400px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .edit {
              display: flex;
              align-items: center;
              justify-content: center;
              outline: none;
              border: none;
              cursor: pointer;
              background-color: transparent;
              margin-left: 5px;
              border-radius: 4px;

              &:hover {
                background-color: #F5F6F7;
              }
            }
          }

          .table-input {
            width: calc(100% - 100px);
            background: #FFF;
            border: 1px solid #DEE2E6;
            border-radius: 4px;
            outline: none;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #495057;
            padding: 5px 10px;
          }
        }

        &.closingTime {
          &.diff {
            color: #12B886;
          }
        }
      }
    }

    .header {
      color: #9fa4ab;

      > tr > th {
        font-weight: 500;
      }
    }
  }
}

.info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  background: #F5F6F7;
  border-radius: 6px;
  margin-bottom: 10px;

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .paragraph {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #495057;
      text-align: center;
      margin: 0;

      &.red {
        margin-top: 6px;
        color: #FA5252;
      }
    }
  }
}

.more {
  position: relative;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  background-image: url('../../../../asset/img/dot_vertical.svg');
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 100;
  border-radius: 4px;

  &:hover, &.active {
    background-color: #e1e8ee;
  }

  &:disabled {
    cursor: not-allowed;
    background-image: url('../../../../asset/img/dot_vertical_disabled.svg');
  }
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #F8F9FA;
  border-radius: 8px;
  color: #343A40;

  .img {
    position: relative;
    width: 100px;
    height: 100px;
    display: block;
    margin-bottom: 24px;
    background: url('../../../../asset/img/icon_page_list_user_mono.svg');
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #343A40;
    margin-bottom: 8px;
  }

  .desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #495057;
  }
}