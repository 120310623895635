.container {
  width: calc(100% - 100px);
  height: calc(100% - 50px);

  &.padding {
    padding-right: 0;
  }
}

.content {
  position: relative;
  display: flex;
  justify-content: center;
  max-height: calc(100% - 50px);
  height: 100%;
  overflow: auto;

  .close {
    position: absolute;
    width: 50px;
    height: 50px;
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer;
    right: 30px;
  }

  .right {
    display: flex;
    flex-direction: column;
    width: 960px;
    padding-right: 40px;
    overflow: auto;
  }
}

.modal-footer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 100px);
  height: 90px;
  bottom: 25px;
  left: 50px;
  padding-left: 459px;
  border-top: 1px solid #E7E8EA;
  padding-right: 220px;

  .right {
    display: flex;
    align-items: center;
    max-width: 652px;

    button {
      margin-right: 5px;
    }
  }
}