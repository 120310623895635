.container {
  position: relative;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .header-wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 47px;
    margin-bottom: 24px;
    border-bottom: 1px solid #EAEBEE;

    .status {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      margin-right: 20px;

      &.START {
        background: #40C057;
      }

      &.END {
        background: #adb5bd;
      }

      &.EDITING {
        background: #15AABF;
      }

      &.READY {
        background: #82C91E;
      }
    }

    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #343A40;
      margin: 0 8px 0 0;
    }

    .icon-button {
      width: 32px;
      height: 32px;
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
    }
  }

  .toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .search {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 18px;
        height: 18px;
        top: 9px;
        left: 12px;
        background: url('../../../asset/icon/16_search.svg') no-repeat;
        z-index: 1;
      }

      >input {
        position: relative;
        width: 248px;
        height: 36px;
        background: #FFF;
        border: 1px solid #DEE2E6;
        border-radius: 4px;
        outline: none;
        padding-left: 40px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #343A40;

        &:disabled {
          cursor: not-allowed;
        }

        &::placeholder {
          color: #868E96;
        }
      }
    }

    .align {
      display: flex;
      align-items: center;
    }
  }
}