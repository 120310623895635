.container {
  .tool {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-right: 20px;
    justify-content: space-between;

    .left {
      display: flex;

      .margin {
        margin-right: 20px;
      }
    }
  }

  .table {
    width: 100%;
    text-align: center;
    font-size: 14px;
    border-collapse: initial;
    border-spacing: 0 10px;

    .row {
      position: relative;
      transition: all 0.4s;
      cursor: pointer;

      &:hover {
        background: #effdf3;
        border-radius: 8px;
        box-shadow: 0 0 0 1px #52c564;
        cursor: pointer;

        td {
          position: relative;
          border-color: #52c564 !important;

          &.internalName {
            text-decoration: underline;
          }

          .icon.plus {
            background-color: #effdf3;
            border-color: #41ac4d;
            color: #41ac4d;

            svg {
              fill: #41ac4d;
            }
          }
        }
      }

      > td {
        border-top: 1px solid #dbdde0;
        border-bottom: 1px solid #dbdde0;
        padding: 16px 0;
        transition: all 0.4s;

        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          border-left: 1px solid #dbdde0;
        }

        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border-right: 1px solid #dbdde0;
        }
      }
    }

    .header {
      color: #9fa4ab;

      > tr > th {
        font-weight: 500;
      }
    }
  }

}


.button {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 2px solid #343A40;
  border-radius: 30px;
  background: #e6e8eb;
  font-weight: bold;
  padding: 10px 15px;
  cursor: pointer;
  transition: all .3s;


  &:hover {
    background: #d7dbdf;
  }

  &:disabled {
    cursor: not-allowed;

  }
}

.input {
  position: relative;
  height: 32px;
  padding: 0 10px;
  transition: all .3s;
  border-radius: 4px;
  outline: none;
  border: 1px solid #d7dbdf;
  width: 200px;


  &:focus, &:hover {
    border: 1px solid #5eb0ef;
    box-shadow: 0 0 0 1px #5eb0ef;
  }
}

.more {
  position: relative;
  width: 32px;
  height: 32px;
  border: none;
  background-color: transparent;
  background-image: url('../../../asset/img/dot_vertical.svg');
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 100;
  border-radius: 4px;

  &:hover, &.active {
    background-color: #e1e8ee;
  }

  &:disabled {
    cursor: not-allowed;
    background-image: url('../../../asset/img/dot_vertical_disabled.svg');
  }
}

.link {
  color: #2B53AE;
  text-decoration: none;
}

.check {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  z-index: 10;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked + .marker::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: #52C564;
      background-image: url("../../../asset/img/checked.svg");
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 4px;
    }
  }

  .marker {
    position: absolute;
    left:0;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #c2c9ce;
  }
}

.disabled {
  color: #ccc !important;
  cursor: not-allowed !important;

  &:hover {
    background: white !important;
  }
}