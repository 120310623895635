.indicator {
  &.tiny {
    padding: 4px;
    height: 28px;
  }

  &.small {
    padding: 4px;
    height: 30px;
  }

  &.large {
    padding: 10px;
    height: 44px;
  }

  > svg {
    fill: #495057;
    transition: all 0.4s;
  }

  &.focused {
    > svg {
      fill: #25282b;
    }
  }

  &.disabled {
    > svg {
      fill: #adb5bd;
    }
  }
}
