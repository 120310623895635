.container {
  position: relative;
  width: 1100px;

  .sub {
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .job-groups {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 16px;


      .list-wrapper {
        > h5 {
          margin: 0 0 8px 0;
        }
        .list {
          display: flex;
          flex-direction: column;
          margin-top: 8px;
          max-height: 270px;
          flex-wrap: wrap;
          column-gap: 8px;

          .item {
            margin-bottom: 4px;
            font-size: 14px;
            font-weight: 500;
            display: flex;
            flex-direction: row;
            gap: 2px;
            width: fit-content;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            padding: 2px 2px;
            cursor: pointer;
            user-select: none;

            span {
              &:not(:first-child) {
                cursor: pointer;
                padding: 0 4px;
                border-left: 1px solid #e0e0e0;
                color: green;

                &.unable {
                  color: red;
                }
              }
            }
          }
        }
      }
    }
  }

  .form {
    .submit-area {
      display: flex;
      flex-direction: row;
        gap: 8px;
      width: 100%;

      .input {
        height: 32px;
        flex:1;
      }
    }
  }
}

.error {
  display: flex;
  flex-direction: row;
  gap: 8px;
  color: red;
  align-items: center;
}