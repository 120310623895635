.table {
    width: 100%;
    text-align: center;
    font-size: 14px;
    border-collapse: initial;
    border-spacing: 0 10px;

    > tbody {
        > tr {
            position: relative;
            transition: all 0.4s;
            cursor: pointer;

            &:hover {
                background: #effdf3;
                border-radius: 8px;
                box-shadow: 0 0 0 1px #52c564;
                cursor: pointer;

                td {
                    position: relative;
                    border-color: #52c564 !important;

                    &.internalName {
                        text-decoration: underline;
                    }

                    .icon.plus {
                        background-color: #effdf3;
                        border-color: #41ac4d;
                        color: #41ac4d;

                        svg {
                            fill: #41ac4d;
                        }
                    }
                }
            }
        }


        td {
            border-top: 1px solid #dbdde0;
            border-bottom: 1px solid #dbdde0;
            padding: 16px 0;
            transition: all 0.4s;

            &:first-child {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                border-left: 1px solid #dbdde0;
            }

            &:last-child {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                border-right: 1px solid #dbdde0;
            }
        }
    }

    thead {
        color: #9fa4ab;

        > tr > th {
            font-weight: 500;
        }
    }
}
