.container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: "";
    position: absolute;
    width: 400px;
    height: 400px;
    background: #c1efe3;
    border-radius: 100%;
    filter: blur(100px);
    z-index: -1;
  }

  .content {
    strong {
      display: inline-block; margin-right: 20px;
    }
  }
}