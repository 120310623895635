@import '../../../styles/colors';

.error {
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #FA5252;
  margin-bottom: 6px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px 50px;
  min-height: 100vh;
  min-width: 100vw;

  .wrapper {
    width: 544px;

    h1 {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $color-gray-800;
      margin-bottom: 8px;
    }

    h2 {
      font-weight: 700;
      font-size: 28px;
      line-height: 40px;
      color: $color-gray-800;
      margin-bottom: 48px;
    }
  }
}


.form {
  button.request-auth-button {
    background-color: white;
  }

  > label {
    display: block;
    margin-top: 25px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $color-gray-800;
  }

  .input-text {
    margin: 8px 0;

    &::after {
      position: absolute;
      width: 100%;
      height: 30px;
      background: $color-red-100;
      content: '';
      right: 0;
      top: 0;
    }
  }

  .auth-number-input {
    margin-top: 20px;
  }

  .info-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 48px;
    padding: 16px 30px;
    background: $color-blue-50;
    border-radius: 8px;

    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: $color-blue-600;
  }

  .submit-button {
    width: 100%;
    height: 64px;
    background: $color-gray-800;
    border-radius: 8px;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: white;

    &:hover {
      background: $color-gray-900;
    }
  }

  .defer-change-button {
    text-align: center;
    text-decoration: underline;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.05em;
    color: $color-gray-800;

    &:hover {
      cursor: pointer;
    }
  }
}
