.button {
  border: 1px solid #656a71;
  border-radius: 4px;
  background: white;
  outline: none;
  padding: 13px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:disabled {
    border: 1px solid #DEE2E6;
    cursor: not-allowed;
  }
}