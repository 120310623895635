.wrapper {
  position: relative;
  user-select: none;
  display: flex;
}

.options {
  z-index: 404;
  position: fixed;
  width: max-content;
  box-shadow: 0 6px 15px rgb(0 0 0 / 15%), 0 4px 6px rgb(0 0 0 / 5%);
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}