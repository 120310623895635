.input {
  width: 0;
  margin: 0;

  + .label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 1px solid #ced4da;
    box-sizing: border-box;
    vertical-align: top;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.4s;

    > svg {
      fill: white;
      transition: all 0.4s;
    }
  }

  &:hover {
    + .label {
      background-color: white;
      border-color: #adb5bd;
      box-shadow: 0 0 0 1px #adb5bd;

      & + label {
        cursor: pointer;
      }
    }
  }

  &:focus {
    + .label {
      border-color: #12b886;
    }
  }

  &:disabled {
    + .label {
      background-color: #e9ecef;
      border-color: #e9ecef;
      box-shadow: none;
      cursor: not-allowed;

      > svg {
        fill: #e9ecef;
      }
    }

    &.checked {
      background-color: #e9ecef;
      border-color: #e9ecef;

      + .label > svg {
        fill: #adb5bd;
      }
    }
  }

  &.checked {
    + .label {
      background-color: #12b886;
      border-color: #12b886;
      background-image: url("../../../../asset/img/checked.svg");
      background-position: center;
      background-repeat: no-repeat;
    }

    &:hover {
      + .label {
        background-color: #0ca678;
        border-color: #0ca678;
        box-shadow: none;

        > svg {
          fill: white;
        }
      }
    }

    &:focus {
      + .label {
        border-color: #41ac4d;
      }
    }

    &:disabled {
      + .label {
        background-color: #e9ecef;
        border-color: #e9ecef;
        box-shadow: none;

        > svg {
          fill: #adb5bd;
        }
      }
    }
  }

  &.variant-round {
    + .label {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      border: 1px solid #e9ecef;

      > svg {
        fill: #ced4da;
      }
    }

    &:hover + .label {
      background-color: white;
      border-color: #adb5bd;
      box-shadow: 0 0 0 1px #adb5bd;
    }

    &:focus + .label {
      box-shadow: 0 0 0 1px #f1f3f5;
    }

    &:disabled {
      + .label {
        background-color: #e9ecef;
        border-color: #e9ecef;
        box-shadow: none;

        > svg {
          fill: #e9ecef;
        }
      }
    }

    &.checked {
      + .label {
        border-color: #12b886;
        background-color: #12b886;
        box-shadow: none;

        > svg {
          fill: white;
        }
      }

      &:hover {
        + .label {
          border-color: #41ac4d;
          background-color: #41ac4d;
          box-shadow: none;

          > svg {
            fill: white;
          }
        }
      }

      &:focus {
        + .label {
          border-color: #12b886;
          background-color: #12b886;
          box-shadow: 0 0 0 1px #c3fae8;

          > svg {
            fill: white;
          }
        }
      }

      &:disabled {
        + .label {
          background-color: #e9ecef;
          border-color: #e9ecef;
          box-shadow: none;

          > svg {
            fill: #adb5bd;
          }
        }
      }
    }
  }
}

// TODO :: size, varient 개발 필요
.label-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  padding-left: 10px;
  cursor: pointer;
}
