.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  position: relative;

  > .picker-wrap {
    display: flex;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 2px 6px 20px 0 #c4c4c4;

    > .picker {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 16px;
      border: 1px solid #dee2e6;
      border-radius: 4px;
      background: white;

    }
  }


}