.container {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 500px;
    height: 500px;
    background: #c1efe3;
    border-radius: 100%;
    filter: blur(150px);
    z-index: -1;
  }

  &::after {
    position: absolute;
    content: "";
    width: 500px;
    height: 500px;
    right: 0;
    bottom: 0;
    background: #f8d8e7;
    border-radius: 100%;
    filter: blur(150px);
    z-index: -1;
  }

  .title {
    font-size: 30px;
    line-height: 40px;
  }

  .tab {
    margin-bottom: 20px;
  }

  .sub {
    font-size: 14px;
    color: #067a6f;
    display: block;
    margin-bottom: 10px;
    margin-top: 30px;
  }

  .format {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 32px;

    & + .format {
      margin-top: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .th {
      min-width: 150px;
      font-size: 14px;
      font-weight: bold;
    }

    .text {
      padding: 3px 24px 3px 8px;
      font-size: 14px;

      &.excel-download {
        color: blue;
        text-decoration: underline;
        cursor: pointer;
      }

      &.cursor {
        position: relative;
        cursor: pointer;
        border-radius: 4px;
        box-shadow: rgb(0 0 0 / 14%) 0 2px 10px;

        &::before {
          content: "";
          position: absolute;
          right: 5px;
          top:5px;
          width: 14px;
          height: 14px;
          background: url('../../../asset/img/down_simple.svg');
        }
      }

      &.download-license {
        margin-left: 10px;
      }
    }

    &.save-guide {
      color: red;
      margin-top: 0;
    }
  }
}

.red {
  color: red;
}

.input {
  position: relative;
  height: 32px;
  padding: 0 10px;
  transition: all .3s;
  border-radius: 4px;
  outline: none;
  border: 1px solid #d7dbdf;
  width: 225px;


  &:focus, &:hover {
    border: 1px solid #5eb0ef;
    box-shadow: 0 0 0 1px #5eb0ef;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  border-radius: 20px;
  background: #e6e8eb;
  font-weight: bold;
  padding: 8px 20px;
  cursor: pointer;
  transition: all .3s;
  font-size: 14px;
  margin-top: 30px;

  &:hover {
    background: #d7dbdf;
  }
}

.auto-level-select {
  width: 200px;
}