.list-popover {
  width: 150px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      position: relative;
      padding: 6px 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #656a71;
      cursor: pointer;
      border-radius: 4px;
      transition: all 0.4s;

      &:hover {
        background: #f4f4f5;
        color: #25282b;
      }
    }
  }
}
