.container {
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #343A40;
    padding: 0;
    margin-bottom: 30px;
  }

  .box {
    padding: 40px;
    width: 900px;
    border: 1px solid #DEE2E6;
    border-radius: 16px;


    .sub-box {
      margin-bottom: 56px;

      .sub-title {
        display: block;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #343A40;
        margin-bottom: 24px;
      }

      .picker {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #343A40;
        padding: 14px;
        border: 1px solid #CED4DA;
        border-radius: 6px;
        width: 150px;
        margin-right: 5px;
      }

      .textarea {
        width: 100%;
        height: 100px;
        resize: none;
        background: #FFF;
        border: 1px solid #E7E7E7;
        border-radius: 4px;
        padding: 14px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #343a40;
        outline: none;

        &::placeholder {
          color: #848991;
        }
      }

      .margin-left {
        margin-left: 8px;
      }
    }
  }
}