.toolbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 36px;
  margin-bottom: 24px;

  .search {
    position: relative;
    display: flex;
    gap: 16px;

    &::before {
      content: "";
      position: absolute;
      width: 18px;
      height: 18px;
      top: 9px;
      left: 12px;
      background: url('../../../asset/icon/16_search.svg') no-repeat;
      z-index: 1;
    }

    input {
      position: relative;
      width: 360px;
      height: 36px;
      background: #FFF;
      border: 1px solid #DEE2E6;
      border-radius: 4px;
      outline: none;
      padding-left: 40px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #343A40;

      &::placeholder {
        color: #868E96;
      }
    }
  }
}

.column-group > td {
  background: #F7F7F8;
  border-right: 10px solid white;
  padding: 5px 0;

  & + td {
    border-left: 10px solid white;
  }

  &:last-child {
    border-right: none;
  }
}

.filter {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.table-wrap {
  overflow-x: auto;
}

