.button {
  border-radius: 4px;
  outline: none;
  font-weight: 700;
  color: #FFF;
  cursor: pointer;
  background: #343A40;
  border: 1px solid #343A40;

  &.small {
    padding: 6px 26px;
    font-size: 13px;
    line-height: 20px;
  }

  &.medium {
    padding: 10px 26px;
    font-size: 16px;
    line-height: 22px;
  }

  &.large {
    padding: 12px 24px;
    font-size: 14px;
    line-height: 20px;
  }

  &:disabled {
    background: #CED4DA;
    border-color: #CED4DA;
    cursor: not-allowed;
  }
}