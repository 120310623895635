.toggleWrap {
    display: inline-flex;
    align-items: center;
    user-select: none;
    transition: opacity 0.2s;

    > .container {
        cursor: pointer;
        border-radius: 16px;
        width: 56px;
        height: 32px;
        padding: 4px;
        background: #C4C4C4;
        transition: background 0.2s;
        position: relative;

        > div {
            width: 24px;
            height: 24px;
            transition: all 0.2s;
            border-radius: 12px;
            background: white;
            position: absolute;
            transform: translateX(0);
        }

        &.checked {
            border: none;
            background: #03C803;

            > div {
                transform: translateX(24px);
            }
        }
    }

    > .children {
        margin-left: 8px;
    }

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;

        > .container {
            cursor: unset;
        }
    }

    &.small {
        > .container {
            width: 28px;
            height: 16px;
            padding: 2px;

            > div {
                width: 12px;
                height: 12px;
                border-radius: 6px;
            }

            &.checked > div {
                transform: translateX(12px)
            }
        }
    }

    &.middle {
        > .container {
            width: 42px;
            height: 24px;
            padding: 3px;

            > div {
                width: 18px;
                height: 18px;
                border-radius: 9px;
            }

            &.checked > div {
                transform: translateX(18px)
            }
        }
    }
}
