/* 잡다에서 공통으로 사용 할 Editor 모듈에선 scss 를 사용하지 않기 때문에 툴바 스타일을 제외한 스타일들은 scss 문법을 사용해서는 안됨 */

/* --- RESET CSS START --- */

.editor * {
  margin: 0;
  padding: 0;
  border: 0;
  text-align: left;
  font-size: 100%;
  font-family: Pretendard, "Noto Sans KR", sans-serif;
  vertical-align: baseline;
  word-break: break-all;
  color: #121619;
}

/* --- RESET CSS END --- */

/* --- TOOLBAR CSS START --- */

.toolbar {
  display: flex;
  align-items: center;
  margin-bottom: 48px;

  > .button {
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    margin-right: 15px;
    background: transparent;
    border-radius: 4px;
    transition: all 0.4s;

    svg {
      transition: all 0.4s;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: #e9ecef;
    }

    &.active {
      background-color: #495057;

      svg {
        fill: white;
      }
    }

    &.text-type {
      line-height: 24px;
      height: 24px;
      font-size: 16px;
      font-weight: bold;
      vertical-align: top;
    }

    .down-arrow {
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 5px solid black;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
  }
}

.text-type-popover {
  width: 100px;
  padding: 10px 5px;
  z-index: 10000;

  > ul > li {
    font-weight: bold;
    color: #25282b;
    line-height: 20px;
    padding: 6px 10px;

    &.a1 {
      font-size: 24px;
    }

    &.a2 {
      font-size: 20px;
    }

    &.a3 {
      font-size: 17px;
    }
  }
}

.hyperlink-popover {
  position: relative;
  padding: 11px 11px 11px 13px;
  width: 320px;
  border: 1px solid #ced4da;
  box-shadow: 0 2px 4px rgb(0 0 0 / 6%), 0 4px 6px rgb(0 0 0 / 10%);
  border-radius: 4px;
  z-index: 1002;

  .wrapper {
    display: flex;
    width: 100%;

    .input {
      flex: 1;
      border: none;
      outline: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      &::placeholder {
        color: #adb5bd;
      }
    }

    .icon-button {
      flex: none;
      fill: #adb5bd;
      transition: all 0.4s;

      &.active {
        fill: #12b886;
        cursor: pointer;
      }
    }
  }
}

.hovering-toolbar {
  position: fixed;
  z-index: 1001;
  opacity: 0;
  padding: 15px;
  border: 1px solid #e9ecef;
  box-shadow: 0 8px 16px rgb(0 0 0 / 12%);
  background: white;
  border-radius: 4px;
  transition: all 0.4s;
}

/* --- TOOLBAR CSS END --- */

.editor > * {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #495057;
  padding: 6px 0;
}

.editor .placeholder {
  color: #ced4da;
  opacity: 1 !important;
}

.editor h1 {
  margin-top: 34px;
  padding: 10px 0;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  color: #343a40;
}

// deprecated
.editor h2 {
  margin: 24px 0 16px;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: #495057;
}

.editor ul,
.editor ol {
  list-style: none;
  padding-left: 0;
}

.editor ol {
  counter-reset: list;
}

.editor ol > li {
  display: flex;
}

.editor ul > li {
  display: flex;
}

.editor ul > li::before {
  flex-shrink: 0;
  content: "";
  width: 12px;
  height: 22px;
  background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.44358 10.0088L0.75 6.31527L1.73341 5.33186L4.47456 8.07301L10.2976 2.25L11.25 3.20243L4.44358 10.0088Z' fill='%23121619' /%3E%3C/svg%3E") no-repeat center;
  margin-right: 4px;
  display: block;
}

.editor ol > li:last-child,
.editor ul > li:last-child {
  padding-bottom: 0;
}

.editor ol > li::before {
  flex-shrink: 0;
  counter-increment: list;
  content: counters(list, "-") ". ";
  display: inline-block;
}

.editor strong {
  padding: 2px 4px;
  background: #f4f4f5;
  font-weight: unset;
}

.editor a {
  color: #2b53ae;
  text-decoration: underline;
  cursor: pointer;
}

.file-input {
  width: 0;
  height: 0;
}

@media (width <= 720px) {
  .editor > * {
    font-size: 13px;
    line-height: 22px;
  }

  .editor h1 {
    font-size: 18px;
  }

  .editor h2 {
    font-size: 17px;
  }
}
