.tab {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  width: 100%;
  height: 50px;
  min-height: 50px;
  border-bottom: 1px solid #EAEBEE;

  > li {
    width: 150px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;

    &.active::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      color: #343A40;
      border-bottom: 2px solid #343A40;
    }

    &.disabled {
      cursor: not-allowed;
      color: #9FA4AB;
    }
  }
}